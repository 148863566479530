import { createTheme } from "@mui/material";


// let theme = createTheme()

let theme = createTheme({
  mobileMenu: {
    width: '300px'
  },
  spacing: [0, 4, 8, 12, 16, 20, 24, 32, 40, 56, 64, 72, 96, 128, 160].map(px => `${px / 16}rem`),
  palette: {
    primary: {
      main: '#252A2E',
      light: '#70767B',
      dark: '08090A',
    },
    grey1: '#FAFAFA',
    grey2: '#F2F2F3',
    grey3: '#ECECEC',
    grey4: '#E8E9EB',
    grey5: '#DADADA',
    grey6: '#4a4a4a',
    white: '#fff',
    black: '#252A2E'
  },
  // Typography
  typography: {
    fontWeightRegular: 300,
    fontFamily: [
        'Merriweather Sans',
        'sans-serif'
    ].join(','),

    h1: {
      fontSize: `${51 / 16}rem`,
      lineHeight: `115%`,
      letterSpacing: '0.05em',
      fontWeight: 700,
      fontFamily: 'var(--font-serif)',
      marginBottom: '2.5rem',
    },
    h2: {
      fontSize: `${38 / 16}rem`,
      lineHeight: `115%`,
      letterSpacing: '0.05em',
      fontWeight: 700,
      fontFamily: 'var(--font-serif)',
      marginBottom: '2.5rem',
    },
    h3: {
      fontSize: `${28 / 16}rem`,
      lineHeight: `115%`,
      letterSpacing: '0.05em',
      fontWeight: 600,
      fontFamily: 'var(--font-serif)',
      marginBottom: '1rem',
    },
    h4: {
      fontSize: `${21 / 16}rem`,
      lineHeight: `115%`,
      letterSpacing: '0.05em',
      fontWeight: 400,
      fontFamily: 'var(--font-sans)',
      marginBottom: '1rem',
    },
    bookTitle: {
      fontSize: `${28 / 16}rem`,
      lineHeight: `115%`,
      // letterSpacing: '0.05em',
      fontWeight: 700,
      fontFamily: 'var(--font-serif)',
      '@media (min-width:1200px)': {
        fontSize: `${28 / 16}rem`,
      },
      '@media (min-width:1900px)': {
        fontSize: `${38 / 16}rem`,
      }
    },
    bookAuthor: {
      fontSize: `${21 / 16}rem`,
      lineHeight: `150%`,
      letterSpacing: '0.05em',
      fontWeight: 400,
      fontFamily: 'var(--font-serif)',
      marginBottom: '0.25rem'
    },
    
    bookSubtitle: {
      fontSize: `${21 / 16}rem`,
      lineHeight: `115%`,
      letterSpacing: '0.05em',
      fontWeight: 700,
      fontFamily: 'var(--font-serif)',
      marginBottom: '0.75rem',
      marginTop: '0.5rem'
    },
    bookCollection: {
      fontSize: `${16 / 16}rem`,
      lineHeight: `${24 / 16}rem`,
      letterSpacing: '0.05em',
      fontWeight: 300,
      fontFamily: 'var(--font-serif)',
      marginTop: '0.25rem'
    },
    bookSectionTitle: {
      fontSize: `${28 / 16}rem`,
      lineHeight: `${32 / 16}rem`,
      letterSpacing: '0.05em',
      fontWeight: 700,
      fontFamily: 'var(--font-serif)',
      marginTop: '2rem'
    },
    archiveTitle: {
      fontSize: `${28 / 16}rem`,
      lineHeight: `${36 / 16}rem`,
      letterSpacing: '0.05em',
      fontWeight: 700,
      fontFamily: 'var(--font-sans)',
      marginTop: 0,
      textTransform: 'uppercase'
    },
    bookArchiveTitle: {
      fontSize: `${18 / 16}rem`,
      lineHeight: '115%',
      fontWeight: 600,
      fontFamily: 'var(--font-serif)',
      margin: '0.25rem 0',
    },
    bookArchiveAuthor: {
      fontSize: `${16 / 16}rem`,
      lineHeight: `120%`,
      letterSpacing: '0.05em',
      fontWeight: 300,
      fontFamily: 'var(--font-serif)',
      marginTop: 0,
    },
    bookArchiveSubtitle: {
      fontSize: `${13 / 16}rem`,
      lineHeight: `120%`,
      letterSpacing: '0.05em',
      fontWeight: 500,
      fontFamily: 'var(--font-sans)',
      margin: 0,
    },
    subtitle1: {
      fontSize: `${13 / 16}rem`,
      lineHeight: `${16 / 16}rem`,
      letterSpacing: '0.05em',
      fontWeight: 700,
      fontFamily: 'var(--font-sans)',
      margin:0
    },
    cartSectionTitle: {
      fontSize: `${21 / 16}rem`,
      lineHeight: `115%`,
      fontWeight: 700,
      fontFamily: 'var(--font-sans)',
      margin: 0,
      marginBottom: '1.5rem',
      '@media (min-width:1200px)': {
        fontSize: `${18 / 16}rem`,
      },
    }
  },
  // Breakpoint for responsive design
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      sm2: 550,
      md: 680,
      md2: 810,
      lg: 960,
      xl: 1200,
      xxl: 1400,
      xxxl: 1920,
    },
  },
  transitions: {
    easing: {
      easeOutBack: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
      easeOutCubic: 'cubic-bezier(0.33, 1, 0.68, 1)',
      easeOutQuint: 'cubic-bezier(0.22, 1, 0.36, 1)'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          
          return  {
            transition: 'all 0.2s ease-in',
            lineHeight: '1em',

            ...(ownerState.variant === 'iconLink' && {
              textTransform: 'lowercase',

              '&:hover': {
                color: 'var(--grey6)',
                backgroundColor: 'transparent',
              }
          }),

            ...(ownerState.variant === 'outlined' && {
                border: '2px solid var(--black)',
                color:  'var(--black)',
                borderRadius: 0,
                padding: '1.2em 2em',
                lineHeight: '1em',

                '&:hover': {
                  border: '2px solid var(--black)',
                  color: '#fff',
                  backgroundColor: 'var(--black)',
                }
            }),
            
            ...(ownerState.variant === 'contained' && {
                border: '2px solid var(--black)',
                color:  'var(--white)',
                backgroundColor: 'var(--black)',
                borderRadius: 0,
                padding: '1.2em 2em',
                lineHeight: '1em',
                boxShadow: 'none',

                '&:hover': {
                  border: '2px solid var(--black)',
                  color: '#fff',
                  backgroundColor: 'var(--grey6)',
                  boxShadow: 'none',
                }
            }),
            ...(ownerState.variant === 'underlined' && {
                borderRadius: 0,
                padding: 0,
                lineHeight: '2em',
                cursor: 'pointer',
                position: 'relative',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                color: 'var(--grey6)',
                fontSize: '0.85em',

                '&::after': {
                  content: '""',
                  width: '100%',
                  height: '2px',
                  position: 'absolute',
                  bottom: '0.3em',
                  left:0,
                  backgroundColor: 'var(--black)'
                },

                '&:hover': {
                  background: 'none',
                  color: 'var(--black)'
                }
            }),
            ...(ownerState.disabled && {
              border: 'none',
              color:  'var(--black)',
              borderRadius: 0,
              padding: '1em 2em',
              lineHeight: '1em',
              cursor: 'not-allowed !important',
            }),

          //   ...(ownerState.size === 'small' && {
          //     fontSize: '0.7rem'
          // }),


          }
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: 'h4',
          archiveTitle: 'h1',
          bookArchiveTitle: 'h1',
          bookArchiveSubtitle: 'h2',
          bookArchiveAuthor: 'h3',
          bookAuthor: 'h2',
          bookTitle: 'h1',
          bookSubtitle: 'h3',
          bookSectionTitle: 'h2',
          bookCollection: 'h4'
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => {
          switch (ownerState.variant) {
            case 'outlined':
              return {
                border: '1px solid var(--black)',
                color: 'var(--black)',
                fontWeight: 500,
                transition: 'all 0.2s ease-in-out',

                '&.MuiChip-clickable:hover':{
                  backgroundColor: 'transparent',
                  // color: 'var(--white)'
                  boxShadow: '0 0 0 1px var(--black) inset',
                }
              }
            case 'filled':
              return {
                border: '1px solid var(--black)',
                color: 'var(--white)',
                backgroundColor: 'var(--black)',
                fontWeight: 500,
                // transition: 'width 0.5s ease-out'
              }
            default:
              break;
          }
        },
        deletable: {
          backgroundColor: 'var(--black)',
          color: 'var(--white)'
        },
        deleteIcon: {
          color: 'var(--white)',
          '&:hover': {
            color: 'rgba(255,255,255,0.5)'
          }
        }
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          margin: '0 auto',
        }
      }
    }
  },
});

// console.log(theme)
// console.log(theme.spacing(2), theme.spacing(3), theme.spacing(4))

export default theme;

